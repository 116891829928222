import moment from "moment/moment";
import BigCalendar from "react-big-calendar-like-google";
import "./testDriveCalendar.css";
import React, { useEffect, useState, useRef } from "react";
import TestDriveCalendarFilters from "./TestDriveCalendarFilters/TestDriveCalendarFilters";
import TestDriveCalendarSearch from "./TestDriveCalendarSearch/TestDriveCalendarSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelBooking,
  createBooking,
  loadBrandList,
  loadCalendarEvents,
  loadCommonShowroomList,
  loadDocumentTypeList,
  loadModelList,
  loadVariantList,
  toggleNotification,
  updateBooking
} from "../../../redux-sagas/actions";
import TestDriveCalendarModal from "./TestDriveCalendarModal/TestDriveCalendarModal";
import CustomEvent from "../CustomEvent/CustomEvent";
import CustomToolbar from "../CustomToolbar/CustomToolbar";
import TestDriveOverlay from "../TestDriveOverlay/TestDriveOverlay";
import fetchClient from "../../../services/fetch";
import {
  createNewDate,
  formatDate,
  formatEvents,
  formatTime
} from "../../../utils/formatDateTime";
import getStartEndDates from "../../../utils/getStartEndDates";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { format, addMinutes } from "date-fns";
import useValidateForm from "../../../hooks/useValidateForm";

BigCalendar.momentLocalizer(moment);

const TestDriveCalendar = ({ role, calendarParams, setCalendarParams }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const eventRef = useRef(null);

  // Use Selector
  const calendarEvents = useSelector((state) => state.calendarEvents);
  const marketId = useSelector((state) => state.marketId ?? state.userInfo?.marketId);
  const userInfo = useSelector((state) => state.userInfo);

  // Use State
  const [openModal, setOpenModal] = useState(false);
  const [timeList, setTimeList] = useState([]);
  const [mode, setMode] = useState("edit");
  const [filters, setFilters] = useState({
    brand: { id: "" },
    showroom: { id: "" },
    model: { id: "" },
    variant: { id: "" },
    fuelType: { id: "" },
    vin: { id: "" }
  });
  const el = useRef(null);
  const buttonRef = useRef();
  const isSubmitted = useRef(false);
  const [clicked, setClicked] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [calendarSlot, setCalendarSlot] = useState(null);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [createPayload, setCreatePayload] = useState({
    bookingDate: null,
    startTime: null,
    endTime: null,
    showroomId: null,
    vehicleId: null,
    documentTypeId: null,
    firstname: null,
    lastname: null,
    documentNumber: null,
    email: null,
    phoneNumber: null
  });
  const [updatePayload, setUpdatePayload] = useState({
    bookingDate: null,
    startTime: null,
    endTime: null,
    showroomId: null,
    brandId: null,
    modelId: null,
    variantId: null,
    comments: null
  });
  const [time, setTime] = useState({
    id: null,
    display: null,
    startTime: null,
    endTime: null
  });

  const [brandSettings, setBrandSettings] = useState(null);
  const [showroomInfo, setShowroomInfo] = useState(null);
  const { errors, validateOnSubmit, resetErrors } = useValidateForm();
  const isCreateMode = mode === "create";

  useEffect(() => {
    dispatch(loadDocumentTypeList())
  }, [dispatch])

  useEffect(() => {
    queryBuilder();

    if (userInfo) {
      let { showrooms, accessLevel } = userInfo;
      let showroomId = accessLevel === 1 && showrooms.length > 0 ? showrooms[0].showroomId : filters.showroom.id;
      if (showroomId) {
        fetchClient().get(`/showrooms/${showroomId}`)
          .then(res => {
            setShowroomInfo(res.data.data);
          });
      }
    }

  }, [calendarParams, filters]);

  const calendarQuery = () => {
    return `?search=${calendarParams.search}&brandId=${filters.brand.id ?? ""
      }&showroomId=${filters.showroom.id ?? ""}&modelId=${filters.model.id ?? ""
      }&variantId=${filters.variant.id ?? ""}&fuelTypeId=${filters.fuelType.id ?? ""
      }&vehicleId=${filters.vin.id ?? ""}&fromDate=${calendarParams.fromDate ?? ""
      }&toDate=${calendarParams.toDate ?? ""}`;
  };

  // Fetch calendar events
  const queryBuilder = () => {
    dispatch(loadCalendarEvents(calendarQuery()));
  };

  const onNavigate = (date, view) => {
    const { from, to } = getStartEndDates(date, view);
    setCalendarParams({
      ...calendarParams,
      currentDate: date,
      fromDate: from,
      toDate: to
    });
  };

  const calendarView = (props) => onNavigate(calendarParams.currentDate, props);

  // Custom header for week and day view
  const customWeekDayViewHeader = (props) =>
    getTranslation(moment(props.date).format("ddd"), t) +
    " " +
    moment(props.date).format("DD");

  const customMonthHeader = (props) => getTranslation(props.label, t);

  const getBrandSettings = (brandId) => fetchClient()
    .get(`/markets/${marketId}/brands/${brandId}/settings`)
    .then(res => {
      setBrandSettings(res.data.data);
    })

  const handleCloseModal = () => {
    isSubmitted.current = false;
    setOpenModal(false);
    resetErrors();
  }

  const handleContinueSubmit = () => {
    const params = calendarQuery();
    dispatch(isCreateMode ? createBooking(createPayload, params) : updateBooking(
      {
        ...updatePayload,
        bookingDate: formatDate(updatePayload.bookingDate)
      },
      params,
      selectedEvent.eventId
    ));
    handleCloseModal();
  }

  const handleSubmitCancel = () => {
    const params = calendarQuery();
    dispatch(cancelBooking({ id: selectedEvent.eventId, params }));
    handleCloseModal();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    isSubmitted.current = true;
    if (isCreateMode) validateOnSubmit(createPayload)
    else handleContinueSubmit();
  }

  useEffect(() => {
    if (!Object.keys(errors).length && isSubmitted.current && isCreateMode)
      handleContinueSubmit();
  }, [errors])

  const handleSelectEvent = (e) => {
    if (e.eventType) {
      const { eventData, vehicleId } = e;
      eventRef.current = e;
      fetchClient()
        .get(`/vehicles/${vehicleId}/timeslots?date=${formatDate(e.startDate)}`)
        .then((res) => {
          setTimeList(res.data.data);
          setTime(res.data.data.find((time) => time.startTime === e.startTime));
          setMode("edit");
          setSelectedEvent(e);
          setUpdatePayload({
            bookingDate: new Date(e.startDate),
            startTime: e.startTime,
            endTime: e.endTime,
            brandId: eventData.brandId,
            modelId: eventData.modelId,
            variantId: eventData.variantId,
            showroomId: eventData.showroomId,
            fuelTypeId: eventData.fuelTypeId,
            vehicleId: vehicleId,
            comments: eventData.comments
          });
          getBrandSettings(eventData.brandId);
          dispatch(loadBrandList());
          dispatch(loadModelList());
          dispatch(loadVariantList());
          dispatch(loadCommonShowroomList());

          // el.current = document.getElementsByClassName(
          //   e.eventStartDate.toISOString()
          // )[formatDate(e.eventStartDate) === formatDate(new Date()) ? 1 : 0];

          el.current = document.getElementById(e.eventId);
          setClicked(el.current);
          setTimeout(() => buttonRef.current.click(), 100);
        });
    }
  };

  const handleSelectSlot = (e) =>
    fetchClient()
      .get(`/commondata/vehicles/info?vehicleId=${filters.vin.id}`)
      .then((response) => {
        const { data } = response.data;
        setVehicleInfo(data);
        setMode("create");
        setCalendarSlot(e);
        setOpenModal(true);
        setCreatePayload({
          showroomId: data.showroomId,
          vehicleId: data.vehicleId,
          bookingDate: formatDate(e.start),
          startTime: formatTime(e.start),
          endTime: formatTime(e.end)
        });
      })
      .catch((err) => {
        console.error(err);
      });

  const handleButtonAction = (action) => {
    setOpenModal(false);

    if (action === "edit") {
      setMode("edit");
      setOpenModal(true);
    } else if (action === "cancel") {
      setMode("cancel");
      setOpenModal(true);
    }
  };

  const handleClickShowNoShow = (type) => fetchClient().put(`/bookings/${eventRef.current.eventId}/${type}?clientDateTime=${format(new Date(), "yyyy-MM-dd HH:mm")}`).then(res => {
    queryBuilder();
    buttonRef.current.click();
    dispatch(toggleNotification({
      isOpen: true,
      type: "success",
      content1: getTranslation("The client with Document number", t),
      highlight1: `${eventRef.current.eventData.documentNumber}, ${type === "show" ? getTranslation("ATTENDED", t) : getTranslation("DID NOT ATTEND", t)}`,
      content2: getTranslation("the appointment", t) + ".",
      highlight2: ""
    }))
  }).catch(err => dispatch(toggleNotification({
    isOpen: true,
    type: "error",
    content1: getTranslation(err.response.data.message, t),
    highlight1: "",
    content2: "",
    highlight2: ""
  })));

  const getSlotDisableCondition = (date) => {
    const calendarTime = format(date, "HH:mm:ss");
    const dayNum = date.getDay();

    switch (dayNum) {
      case 0:
        return !showroomInfo.sundayEnabled || showroomInfo.sundayStartTime > calendarTime || showroomInfo.sundayEndTime < calendarTime
      case 1:
        return !showroomInfo.mondayEnabled || showroomInfo.mondayStartTime > calendarTime || showroomInfo.mondayEndTime < calendarTime
      case 2:
        return !showroomInfo.tuesdayEnabled || showroomInfo.tuesdayStartTime > calendarTime || showroomInfo.tuesdayEndTime < calendarTime
      case 3:
        return !showroomInfo.wednesdayEnabled || showroomInfo.wednesdayStartTime > calendarTime || showroomInfo.wednesdayEndTime < calendarTime
      case 4:
        return !showroomInfo.thursdayEnabled || showroomInfo.thursdayStartTime > calendarTime || showroomInfo.thursdayEndTime < calendarTime
      case 5:
        return !showroomInfo.fridayEnabled || showroomInfo.fridayStartTime > calendarTime || showroomInfo.fridayEndTime < calendarTime
      case 6:
        return !showroomInfo.saturdayEnabled || showroomInfo.saturdayStartTime > calendarTime || showroomInfo.saturdayEndTime < calendarTime
      default:
        break;
    }
  }

  const duration = (vehicleInfo?.defaultTestDriveDuration ?? brandSettings?.defaultTestDriveDuration) ?? 30;

  return (
    <div className="test-drive-calendar-container">
      <div className="filters-container">
        <TestDriveCalendarFilters
          filters={filters}
          setFilters={setFilters}
          role={role}
          setBrandSettings={setBrandSettings}
          getBrandSettings={getBrandSettings}
          setShowroomInfo={setShowroomInfo}
          setVehicleInfo={setVehicleInfo}
        />
      </div>
      <div className="search-filters-container">
        <TestDriveCalendarSearch
          role={role}
          calendarParams={calendarParams}
          setCalendarParams={setCalendarParams}
        />
      </div>
      <div className="calendar-container">
        <BigCalendar
          events={formatEvents(calendarEvents).map((item) => ({
            ...item,
            eventTitle: getTranslation(item.eventTitle, t)
          }))}
          startAccessor="eventStartDate"
          endAccessor="eventEndDate"
          titleAccessor="eventTitle"
          defaultDate={new Date()}
          onNavigate={(date, view) => onNavigate(date, view)}
          selectable
          timeslots={1}
          onSelecting={(slot) => false}
          step={30}
          slotPropGetter={(date) => {
            let className = date.toISOString();
            const today = new Date();
            const calendarDate = new Date(date);
            if (today >= date || (showroomInfo !== null && getSlotDisableCondition(calendarDate)))
              className += " disabled";
            return { className };
          }}
          eventPropGetter={(event) => ({ id: event.eventId })}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={(e) => {
            const today = new Date();
            var eventStart = new Date(e.start);
            e.end = new Date(eventStart.getTime() + duration * 60000);
            if (filters.vin.id && e.start >= today 
              && !getSlotDisableCondition(new Date(e.start))
              && !getSlotDisableCondition(new Date(e.end))) {
              handleSelectSlot(e);
            }
          }}
          components={{
            toolbar: CustomToolbar,
            event: CustomEvent,
            week: {
              header: customWeekDayViewHeader
            },
            day: {
              header: customWeekDayViewHeader
            },
            month: {
              header: customMonthHeader
            }
          }}
          onView={calendarView}
          defaultView="week"
          min={createNewDate("6:00:00")}
          max={createNewDate("18:30:00")}
          messages={{
            showMore: (total) => `+${total} ${getTranslation("more", t)}`
          }}
        />
      </div>
      {clicked && (
        <TestDriveOverlay
          el={el}
          buttonRef={buttonRef}
          modalAction={handleButtonAction}
          editMode={mode}
          handleClickShowNoShow={handleClickShowNoShow}
          event={eventRef.current}
        />
      )}
      {openModal && (
        <TestDriveCalendarModal
          filters={filters}
          mode={mode}
          open={openModal}
          closeModal={handleCloseModal}
          handleSubmit={handleSubmit}
          handleSubmitCancel={handleSubmitCancel}
          eventProps={isCreateMode ? calendarSlot : selectedEvent}
          vehicleInfo={vehicleInfo}
          createPayload={createPayload}
          setCreatePayload={setCreatePayload}
          updatePayload={updatePayload}
          setUpdatePayload={setUpdatePayload}
          errors={errors}
          time={time}
          timeList={timeList}
          setTime={setTime}
          setTimeList={setTimeList}
          role={role}
          brandSettings={brandSettings}
        />
      )}
    </div>
  );
};

export default TestDriveCalendar;
