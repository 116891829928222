import { DataGrid } from "@mui/x-data-grid";
import { fetchGeneralReportsColumns } from "../../../../utils/fetchColumns";
import { useSelector } from "react-redux";
import { useState } from "react";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const GeneralGrid = ({ setGeneralQueryParams, generalQueryParams }) => {
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.isLoading);
  const generalReport = useSelector((state) => state.generalReport);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const onPaginationModelChange = (paginationModel) => {
    setPaginationModel(paginationModel);

    setGeneralQueryParams({
      ...generalQueryParams,
      page: JSON.stringify({
        skip: paginationModel.page * paginationModel.pageSize,
        take: paginationModel.pageSize
      })
    });
  };

  const onSortChange = (sortModel) => {
    setGeneralQueryParams({
      ...generalQueryParams,
      sort: sortModel[0] ? JSON.stringify(sortModel) : ""
    });
  };

  return (
    <DataGrid
      getRowId={(row) => row.referenceNumber}
      getRowHeight={() => "auto"}
      columns={fetchGeneralReportsColumns().map((item) => ({
        ...item,
        headerName: getTranslation(item.headerName, t)
      }))}
      rowCount={generalReport.totalRecords || 0}
      rows={generalReport.data}
      loading={isLoading}
      pageSizeOptions={[5, 10, 15, 20]}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={(paginationModel) =>
        onPaginationModelChange(paginationModel)
      }
      slotProps={{
        pagination: {
          labelRowsPerPage: getTranslation("Rows per page:", t)
        }
      }}
      onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
};

export default GeneralGrid;
