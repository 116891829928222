import { DataGrid } from "@mui/x-data-grid";
import {
  fetchAppointmentLogColumns,
  fetchAppointmentStatusLogColumns,
  fetchBlockLogColumns,
  fetchShowroomLogColumns
} from "../../../../utils/fetchColumns";
import { useSelector } from "react-redux";
import { useState } from "react";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const LogGrid = ({ logQueryParams, setLogQueryParams }) => {
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.isLoading);
  const logReport = useSelector((state) => state.logReport);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const getColumn = () => {
    switch (logQueryParams.logType) {
      case 1:
        return fetchShowroomLogColumns(t);
      case 2:
        return fetchAppointmentLogColumns().map((item) => ({
          ...item,
          headerName: getTranslation(item.headerName, t)
        }));
      case 3:
        return fetchBlockLogColumns().map((item) => ({
          ...item,
          headerName: getTranslation(item.headerName, t)
        }));
      case 4:
        return fetchAppointmentStatusLogColumns().map((item) => ({
          ...item,
          headerName: getTranslation(item.headerName, t)
        }));
      default:
        return [];
    }
  };

  const getRowIds = () => {
    switch (logQueryParams.logType) {
      case 1:
        return "dateChanged";
      case 2:
        return "createdDate";
      case 3:
        return "createdDate";
      case 4:
        return "dateChanged";
      default:
        return;
    }
  };

  const onPaginationModelChange = (paginationModel) => {
    setPaginationModel(paginationModel);

    setLogQueryParams({
      ...logQueryParams,
      page: JSON.stringify({
        skip: paginationModel.page * paginationModel.pageSize,
        take: paginationModel.pageSize
      })
    });
  };

  const onSortChange = (sortModel) => {
    setLogQueryParams({
      ...logQueryParams,
      sort: sortModel[0] ? JSON.stringify(sortModel) : ""
    });
  };

  return (
    <DataGrid
      getRowId={(row) => (logQueryParams.logType ? row[getRowIds()] : row.id)}
      getRowHeight={() => "auto"}
      columns={getColumn()}
      rowCount={logReport.totalRecords || 0}
      rows={logQueryParams.logType ? logReport.data : []}
      loading={isLoading}
      pageSizeOptions={[5, 10, 15, 20]}
      slotProps={{
        pagination: {
          labelRowsPerPage: getTranslation("Rows per page:", t)
        }
      }}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={(paginationModel) =>
        onPaginationModelChange(paginationModel)
      }
      onSortModelChange={(newSortModel) => onSortChange(newSortModel)}
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
};

export default LogGrid;
