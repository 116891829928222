import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@mui/material";
import "./testDriveRescheduleInformation.css";
import Dropdown from "../../../../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  loadBrandList,
  loadCommonShowroomList,
  loadModelList,
  loadVariantList,
  setModelList,
  setVariantList
} from "../../../../../redux-sagas/actions";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getTranslation } from "../../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { createNewDate, formatDate } from "../../../../../utils/formatDateTime";
import "dayjs/locale/es";
import fetchClient from "../../../../../services/fetch";

const TestDriveRescheduleInformation = ({
  timeList,
  eventProps,
  payload,
  setPayload,
  time,
  setTime,
  setTimeList,
  toggleDisclaimerModal,
  radioBoxValue,
  handleRadioBoxChange,
  brandSettings
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const brandList = useSelector((state) => state.brandList).data;
  const modelList = useSelector((state) => state.modelList).data;
  const variantList = useSelector((state) => state.variantList).data;
  const showroomList = useSelector((state) => state.commonShowroomList).data;
  const { eventData } = eventProps;
  const { termsAndConditionsUrl, dataProtectionPolicyUrl } = brandSettings || {};

  const isTermsAndConditionsUrl = brandSettings && termsAndConditionsUrl;
  const isDataPolicyUrl = brandSettings && dataProtectionPolicyUrl;


  const handleOpen = (name) => {
    switch (name) {
      case "brandId":
        dispatch(loadBrandList());
        break;
      case "showroomId":
        const q = payload.brandId ? `?brandId=${payload.brandId}` : "";
        dispatch(loadCommonShowroomList(q));
        break;
      case "modelId":
        if (payload.brandId) {
          dispatch(loadModelList(`?brandId=${payload.brandId}${payload.showroomId ? '&showroomId=' + payload.showroomId :''}`));
        } else {
          dispatch(setModelList([]));
        }
        break;
      case "variantId":
        if (payload.modelId) {
          dispatch(loadVariantList(`?modelId=${payload.modelId}`));
        } else {
          dispatch(setVariantList([]));
        }
        break;
      default:
        break;
    }
  };

  const isTimeSlotDisabled = (time) => {
    const today = new Date();

    if (formatDate(today) === formatDate(payload.bookingDate)) {
      const currentItem = createNewDate(time);
      return today > currentItem;
    } else if (payload.bookingDate < today) return true;
    else return false;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "brandId":
        setPayload({
          ...payload,
          [name]: value,
          showroomId: null,
          modelId: null,
          variantId: null
        });
        break;
      case "showroomId":
        setPayload({
          ...payload,
          [name]: value,
          modelId: null,
          variantId: null
        });
        break;
      case "modelId":
        setPayload({
          ...payload,
          [name]: value,
          variantId: null
        });
        break;
      case "variantId":
        setPayload({
          ...payload,
          [name]: value
        });
        break;
      case "timePicker":
        const timeValue = timeList.find((time) => time.id === value);
        setTime(timeValue);
        setPayload({
          ...payload,
          startTime: timeValue.startTime,
          endTime: timeValue.endTime
        });
        break;
      case "bookingDate":
        fetchClient()
          .get(`vehicles/${eventProps.vehicleId}/timeslots?date=${formatDate(value)}`)
          .then(res => {
            const newTimeList = res.data.data;
            setTimeList(newTimeList);
            const timeValue = newTimeList.find((time) => time.id === value);
            setTime(timeValue);
          });

        setPayload({ ...payload, [name]: value });
        break;
      case "comments":
        if (!value || value.length <= 100)
          setPayload({ ...payload, [name]: value });
        break;
      default:
        setPayload({ ...payload, [name]: value });
        break;
    }
  };

  return (
    <div className="test-drive-reschedule-information container p-0">
      <div className="reservation-data title">
        <h5>{getTranslation("Reservation information", t)}</h5>
        <div className="row">
          <Dropdown
            className="col-lg-6 ps-0 pe-0 pe-lg-4 mb-4"
            label={"*" + getTranslation("Brand", t)}
            name="brandId"
            data={brandList}
            value={payload.brandId}
            textField="name"
            valueField="id"
            onOpen={() => handleOpen("brandId")}
            handleChange={handleChange}
          />
          <Dropdown
            className="col-lg-6 ps-0 pe-0 ps-lg-4 mb-4"
            label="*Showroom"
            name="showroomId"
            data={showroomList}
            value={payload.showroomId}
            textField="name"
            valueField="id"
            onOpen={() => handleOpen("showroomId")}
            handleChange={handleChange}
          />
          <Dropdown
            className="col-lg-6 ps-0 pe-0 pe-lg-4 mb-4"
            label={"*" + getTranslation("Model", t)}
            name="modelId"
            data={modelList}
            value={payload.modelId}
            textField="name"
            valueField="id"
            onOpen={() => handleOpen("modelId")}
            handleChange={handleChange}
          />
          <Dropdown
            className="col-lg-6 ps-0 pe-0 ps-lg-4 mb-4"
            label={"*" + getTranslation("Variant", t)}
            name="variantId"
            data={variantList}
            value={payload.variantId}
            textField="name"
            valueField="id"
            onOpen={() => handleOpen("variantId")}
            handleChange={handleChange}
          />
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n.language}
          >
            <div className="col-lg-6 ps-0 pe-0 pe-lg-4 mb-4">
              <DatePicker
                className="calendar-input"
                label={getTranslation("Date", t)}
                disabled={false}
                required={true}
                name="bookingDate"
                value={dayjs(payload.bookingDate)}
                onChange={(newValue) =>
                  handleChange({
                    target: { name: "bookingDate", value: newValue }
                  })
                }
                minDate={dayjs(new Date())}
              />
            </div>
          </LocalizationProvider>
          <div className="col-lg-6 ps-0 pe-0 ps-lg-4 mb-4">
            <div className="dropdown-input">
              <FormControl variant="standard">
                <InputLabel>{getTranslation("Time", t)}</InputLabel>
                <Select
                  value={time?.id ?? ""}
                  onChange={handleChange}
                  name="timePicker"
                  IconComponent={AccessTimeIcon}
                >
                  {timeList?.map((timeItem) => (
                    <MenuItem
                      disabled={isTimeSlotDisabled(timeItem.startTime) || !timeItem.isAvailable}
                      key={timeItem.id}
                      value={timeItem.id}
                    >
                      {timeItem.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className="client-data">
        <h5>{getTranslation("Customer information", t)}</h5>
        <div className="row">
          <div className="col-lg-6 ps-0 pe-0 mb-4">
            <span>
              <label className="label-header">
                {getTranslation("Names", t)}:{" "}
                <span className="label-text">{eventData.firstname}</span>
              </label>
            </span>
          </div>
          <div className="col-lg-6 pe-0 ps-5 mb-4">
            <span>
              <label className="label-header">
                {getTranslation("Surname", t)}:{" "}
                <span className="label-text">{eventData.lastname}</span>
              </label>
            </span>
          </div>
          <div className="col-lg-6 ps-0 pe-0 mb-4">
            <span>
              <label className="label-header">
                {getTranslation("Doc. type", t)}:{" "}
                <span className="label-text">
                  {getTranslation(eventData.documentTypeName, t)}
                </span>
              </label>
            </span>
          </div>
          <div className="col-lg-6 pe-0 ps-5 mb-4">
            <span>
              <label className="label-header">
                {getTranslation("Doc. no.", t)}:{" "}
                <span className="label-text">{eventData.documentNumber}</span>
              </label>
            </span>
          </div>
          <div className="col-lg-6 ps-0 pe-0 mb-4">
            <span>
              <label className="label-header">
                E-mail: <span className="label-text">{eventData.email}</span>
              </label>
            </span>
          </div>
          <div className="col-lg-6 pe-0 ps-5 mb-4">
            <span>
              <label className="label-header">
                {getTranslation("Cellphone", t)}:{" "}
                <span className="label-text">{eventData.phoneNumber}</span>
              </label>
            </span>
          </div>
        </div>
        <div>
          <TextField
            className="text-area"
            variant="outlined"
            label={getTranslation("Comments", t)}
            name="comments"
            onChange={handleChange}
            value={payload.comments}
            multiline={true}
            rows={1}
            helperText={
              !payload.comments
                ? 0 + " / 100"
                : payload.comments?.length + " / 100"
            }
          />
        </div>
      </div>
      <div className="terms-condition">
        <div className="row align-items-end">
          <div className="col-lg-8 ps-0 pe-0 mb-4">
            <span>
              <label>
                {getTranslation("I agree", t)}{" "}
                {
                  isTermsAndConditionsUrl
                    ? (<a className="terms-condition-text" href={brandSettings.termsAndConditionsUrl} target={"_blank"} rel="noreferrer">{getTranslation(" to the terms and conditions", t)}</a>)
                    : (<p className="terms-condition-text" onClick={() => toggleDisclaimerModal("terms")}>{getTranslation(" to the terms and conditions", t)}</p>)
                }
              </label>
            </span>
          </div>
          <div className="col-lg-4 pe-0 ps-4 mb-4">
            <FormControl>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={radioBoxValue.terms}
                onChange={handleRadioBoxChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={getTranslation("Yes", t)}
                  labelPlacement="start"
                  name="terms"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={getTranslation("No", t)}
                  labelPlacement="start"
                  name="terms"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="col-lg-8 ps-0 pe-0 mb-4">
            <span>
              <label>
                {getTranslation("I declare that I know and understand the", t)}{" "}
                {
                  isDataPolicyUrl
                    ? (<a className="terms-condition-text" href={brandSettings.dataProtectionPolicyuRL} target={"_blank"} rel="noreferrer">{getTranslation("Personal Data Protection Policy", t)}</a>)
                    : (<p className="terms-condition-text" onClick={() => toggleDisclaimerModal("data-policy")}>{getTranslation("Personal Data Protection Policy", t)}</p>)
                }{" "}
                {getTranslation(
                  "and I expressly authorize the processing of my personal data",
                  t
                )}
              </label>
            </span>
          </div>
          <div className="col-lg-4 pe-0 ps-4 mb-4">
            <FormControl>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={radioBoxValue.dataPrivacy}
                onChange={handleRadioBoxChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={getTranslation("Yes", t)}
                  labelPlacement="start"
                  name="dataPrivacy"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={getTranslation("No", t)}
                  labelPlacement="start"
                  name="dataPrivacy"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestDriveRescheduleInformation;
